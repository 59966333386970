body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Navigation styles */
.navigation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-custom {
  padding: 0 20px;
}

.nav-links-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-item {
  color: #808080;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.nav-item:hover {
  color: #007bff; /* Your accent color - adjust as needed */
}

/* Underline effect on hover */
.nav-item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #007bff; /* Same as hover color */
  transition: width 0.3s ease;
}

.nav-item:hover::after {
  width: 100%;
}

/* Add these styles for the navbar blur effect */
.MuiAppBar-root {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: transparent !important;
}

/* Optional: Add this if you want to ensure the navbar is always on top */
.MuiAppBar-root {
  z-index: 1100;
}

/* Add these styles to prevent text selection */
.MuiAppBar-root a,
.MuiAppBar-root .button,
.MuiAppBar-root .nav-button-holder {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.nav-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

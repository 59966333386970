/* Animation keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  60% {
    opacity: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(1, 214, 253, 0.3);
  }
  50% {
    text-shadow: 0 0 20px rgba(1, 214, 253, 0.5), 0 0 30px rgba(1, 214, 253, 0.3);
  }
  100% {
    text-shadow: 0 0 10px rgba(1, 214, 253, 0.3);
  }
}

/* Animation classes */
.hero-title-animate,
.hero-description-animate,
.hero-cta-animate,
.trusted-by-animate {
  opacity: 0;
  animation: fadeIn 1.5s ease-out forwards;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.fade-in-up {
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

/* Motion component overrides */
.motion-element {
  opacity: initial !important;
  animation: initial !important;
  transform: initial !important;
  transition: all 0.5s ease !important;
}

/* Bounce animation styles */
.bounce-arrow {
  animation: bounce 2s infinite;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  bottom: -20px;
  width: fit-content;
}

.learn-more-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.arrow-icon {
  width: 40px;
  height: 40px;
  background: radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.bounce-arrow:hover .arrow-icon {
  transform: scale(1.1);
  background: radial-gradient(circle at 50% 200%, #3435393d, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353970, #17181a00 75%);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 767px) {
  .bounce-arrow {
    bottom: -10px;
  }
} 
/* General layout styles */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Adjust based on navbar height */
}

/* Custom scrolling animation for section navigation */
html.scrolling-active {
  scroll-behavior: smooth;
  transition: scroll-behavior 2.5s;
}

/* Add scroll margin to section targets to account for fixed navbar */
#hero-section, 
#trusted-by-id, 
#review-section, 
#pricing-section, 
#faq-section,
#features-section {
  scroll-margin-top: 80px;
}

/* Testimonial and Card Styles */
.pricing-list-item.middle {
  margin-top: 2rem;
}

/* Mobile/desktop pricing visibility styles */
@media (max-width: 767px) {
  .mobile-pricing-only {
    display: block;
  }
  
  .desktop-pricing {
    display: none !important;
  }
  
  .pricing-list-item.w-dyn-item:first-child {
    display: none !important; /* Hide Free plan on mobile */
  }
  
  .pricing-list-item.w-dyn-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px auto !important;
  }
  
  .pricing-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .mobile-pricing-only {
    display: none;
  }
  
  .desktop-pricing {
    display: block;
  }
}

.mobile-pricing-section {
  padding: 20px 0;
}

.mobile-pricing-card {
  box-sizing: border-box;
}

.section-title.xl h2 {
  font-size: 32px !important;
}

.section-paddings {
  padding: 20px 0;
}

/* Cross-Platform Support section styles */
#cross-platform-section {
  padding-top: 0px;
  padding-bottom: 40px;
  margin-top: -30px;
}

#cross-platform-section .container {
  padding-top: 0px;
  padding-bottom: 20px;
}

#cross-platform-section .section-center-text {
  margin-bottom: 30px;
}

#review-section {
  padding-bottom: 20px;
}

/* Additional mobile specific padding adjustments */
@media (max-width: 767px) {
  .section-paddings {
    padding: 10px 0;
  }
  
  #pricing-section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .section-center-text {
    margin-bottom: 10px;
  }
  
  .section-title.xl h2 {
    font-size: 28px !important;
    margin-bottom: 5px;
  }
  
  .section-paragraph p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  #cross-platform-section {
    padding-top: 0px;
    padding-bottom: 30px;
    margin-top: -20px;
  }
  
  #cross-platform-section .container {
    padding-top: 0px;
    padding-bottom: 15px;
  }
  
  #cross-platform-section .section-center-text {
    margin-bottom: 20px;
  }
  
  #review-section {
    padding-bottom: 10px;
  }
}

@media (max-width: 480px) {
  #cross-platform-section {
    padding-top: 0px;
    padding-bottom: 25px;
    margin-top: -15px;
  }
  
  #cross-platform-section .section-center-text {
    margin-bottom: 15px;
  }
} 
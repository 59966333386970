/* Hero title styles */
.hero-title {
  font-size: 60px;
  max-width: 100%;
  overflow-wrap: break-word;
  padding: 0 10px;
}

.hero-highlight {
  background: linear-gradient(135deg, #0062ff 0%, #00c6ff 50%, #0062ff 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0,150,255,0.3);
  animation: shine 2s linear infinite;
  white-space: normal;
  display: inline;
}

.stripe-hero-title .hero-highlight {
  font-weight: 700;
  text-shadow: 0 2px 15px rgba(0,150,255,0.4);
}

.nowrap {
  white-space: nowrap;
}

.mobile-only-break {
  display: none;
}

/* Base hero section styles */
.stripe-inspired-hero {
  padding: 150px 0 60px;
  position: relative;
  overflow: hidden;
}

.stripe-hero-container {
  display: flex;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text content styles */
.stripe-hero-content {
  text-align: center;
  max-width: 900px;
  padding: 0 20px;
}

.stripe-hero-title {
  font-size: 95px;
  line-height: 1.05;
  margin-bottom: 30px;
  font-weight: 700;
  letter-spacing: -0.02em;
  background: linear-gradient(135deg, #fff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255,255,255,0.1);
  max-width: 900px;
}

.title-line {
  display: inline;
}

.desktop-only {
  display: inline;
}

.stripe-hero-description {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 36px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

/* CTA buttons */
.stripe-hero-cta {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  max-width: 680px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.stripe-hero-cta .button {
  border-radius: 50px !important;
  padding: 18px 36px !important;
  font-size: 22px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
}

/* Hero background styles */
.hero-background {
  position: relative;
  padding-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: radial-gradient(circle at center, rgba(0, 124, 255, 0.15) 0%, rgba(1, 77, 253, 0.1) 50%, rgba(30, 30, 40, 0.05) 100%);
  box-shadow: inset 0 0 60px rgba(1, 93, 253, 0.3);
  overflow: hidden;
}

.noise-base {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.12;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1.5' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' fill='white'/%3E%3C/svg%3E");
  z-index: 1;
  mix-blend-mode: hard-light;
}

.hero-background-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/* Section highlight */
.section-highlight {
  background: linear-gradient(135deg, #0062ff 0%, #00c6ff 50%, #0062ff 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0,150,255,0.3);
  animation: shine 2s linear infinite;
  display: inline;
  font-weight: 700;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .stripe-hero-title {
    font-size: 85px;
  }
}

@media (max-width: 991px) {
  .stripe-inspired-hero {
    padding: 120px 0 50px;
  }
  
  .stripe-hero-container {
    padding: 0 15px;
  }
  
  .stripe-hero-content {
    max-width: 700px;
    padding: 0 10px;
  }
  
  .stripe-hero-title {
    font-size: 64px;
    text-align: center;
    max-width: 100%;
  }
  
  .stripe-hero-description {
    text-align: center;
    font-size: 20px;
    max-width: 580px;
  }
  
  .stripe-hero-cta {
    justify-content: center;
    max-width: 580px;
  }
  
  .stripe-hero-cta .button {
    padding: 16px 30px !important;
    font-size: 20px !important;
  }
  
  .desktop-title {
    display: none !important;
  }
  
  .mobile-title {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .hero-title {
    font-size: 40px;
    padding: 0 15px;
  }
  
  .hero-highlight {
    font-size: 95%;
  }
  
  .mobile-only-break {
    display: inline-block;
  }
  
  .stripe-inspired-hero {
    padding: 130px 0 40px;
  }
  
  .stripe-hero-title {
    font-size: 58px !important;
  }
  
  .stripe-hero-description {
    font-size: 15px !important;
    line-height: 1.4 !important;
    max-width: 100% !important;
  }
  
  .stripe-hero-cta {
    max-width: 100% !important;
  }
  
  .title-line {
    display: block;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  
  .desktop-only {
    display: none;
  }
  
  .mobile-title {
    font-size: 32px !important;
    line-height: 1.2 !important;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 576px) {
  .stripe-inspired-hero {
    padding: 120px 0 35px;
  }
  
  .stripe-hero-container {
    padding: 0 15px;
  }
  
  .stripe-hero-content {
    padding: 0 10px;
  }
  
  .stripe-hero-title {
    font-size: 44px;
    line-height: 1.2;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .stripe-hero-description {
    display: none; /* Hide paragraph on mobile */
  }
  
  .stripe-hero-cta {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
  }
  
  .stripe-hero-cta .button {
    padding: 14px 20px !important;
    font-size: 16px !important;
    min-height: 46px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    max-width: none;
  }

  .stripe-hero-cta .button svg {
    width: 18px;
    height: 18px;
  }
  
  .mobile-title {
    font-size: 28px !important;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 28px;
    padding: 0 20px 0 20px;
    line-height: 1.4;
    text-align: left !important;
    word-break: keep-all;
  }
  
  .hero-highlight {
    font-size: 90%;
    display: inline;
    white-space: nowrap;
  }
  
  .desktop-break {
    display: none;
  }
  
  .mobile-only-break {
    display: inline-block;
  }
  
  .mobile-text-left {
    text-align: left !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .stripe-inspired-hero {
    padding: 110px 0 30px;
  }
  
  .stripe-hero-container {
    padding: 0 10px;
  }
  
  .stripe-hero-title {
    font-size: 48px !important;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 25px;
  }
  
  .title-line {
    margin-bottom: 4px;
  }
  
  .stripe-hero-cta {
    padding: 0 15px;
    gap: 10px;
    flex-wrap: wrap !important;
  }
  
  .stripe-hero-cta .button {
    padding: 12px 16px !important;
    font-size: 14px !important;
    min-height: 42px;
  }

  .stripe-hero-cta .button svg {
    width: 16px;
    height: 16px;
    margin-left: 6px !important;
  }
  
  .section.overflow-hidden.hero .container .mobile-text-left {
    text-align: left !important;
  }
  
  .mobile-title {
    font-size: 26px !important;
  }
}

@media (max-width: 375px) {
  .stripe-hero-cta {
    padding: 0 10px;
    gap: 8px;
  }
  
  .stripe-hero-cta .button {
    padding: 10px 14px !important;
    font-size: 13px !important;
    min-height: 38px;
  }

  .stripe-hero-cta .button svg {
    width: 14px;
    height: 14px;
    margin-left: 4px !important;
  }
  
  .mobile-title {
    font-size: 24px !important;
  }
}

@media (max-width: 320px) {
  .stripe-hero-cta .button {
    padding: 8px 12px !important;
    font-size: 12px !important;
    min-height: 36px;
  }

  .stripe-hero-cta .button svg {
    width: 12px;
    height: 12px;
    margin-left: 3px !important;
  }
} 
.success-wrapper {
  position: relative;
  z-index: 1;
  background: radial-gradient(circle at 50% 50%, rgba(0, 127, 255, 0.15), rgba(0, 0, 0, 0) 70%);
  border: 1px solid rgba(0, 127, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 127, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
}

.success-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

.success-message {
  color: #b4bcd0;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: center;
}

.success-submessage {
  color: #b4bcd0;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: center;
}

.success-button {
  background-color: #007fff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 0 auto;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.success-button:hover {
  background-color: #0066cc;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 127, 255, 0.3);
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.success-content {
  position: relative;
  z-index: 2;
}

.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000000;
  padding: 20px;
}

.success-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  display: block;
  color: #007fff;
}

.blue-accent {
  color: #007fff;
  font-weight: bold;
}

.success-note {
  margin-top: 15px;
  text-align: center;
  color: #8a94a8;
  font-size: 14px;
}

.success-note a {
  text-decoration: none;
  transition: all 0.2s ease;
}

.success-note a:hover {
  text-decoration: underline;
}

.unlock-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.lock-icon {
  color: #8a94a8;
  margin-right: 15px;
}

.arrow-icon {
  color: #007fff;
  margin-right: 15px;
  font-weight: bold;
}

.unlock-icon {
  color: #007fff;
}

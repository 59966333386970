.company-logo-holder {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.scrolling-logos {
  display: flex;
  animation: scroll 500s linear infinite;
}

.company-logo-container {
  display: flex;
  align-items: center;
}

.company-logo-wrapper {
  margin: 0 20px; /* Adjust spacing between logos */
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Ensure logos wrap seamlessly */
.scrolling-logos .company-logo-container {
  flex-shrink: 0;
}

/* Styled components for Testimonials */
.TestimonialCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.102);
  transition: transform 0.3s ease-in-out;
}

.TestimonialCard:hover {
  transform: translateY(-5px);
}

.TestimonialHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}

.UserInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Platform icon styles */
.PlatformIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
  padding: 16px;
  background: radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.102);
  transition: transform 0.3s ease-in-out;
}

.PlatformIcon:hover {
  transform: translateY(-5px);
}

.PlatformIcon img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

/* Feature Card styles */
.FeatureCard {
  position: absolute;
  width: 260px;
  padding: 24px;
  background: radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.102);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 10;
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.FeatureCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px rgba(0, 98, 255, 0.2);
}

.FeatureIcon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  background: linear-gradient(135deg, #0062ff 0%, #00c6ff 100%);
  box-shadow: 0 4px 20px rgba(0, 98, 255, 0.3);
  flex-shrink: 0;
}

/* FAQ Section styles */
.questions-container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px 0;
}

.question-text {
  font-weight: 700;
  color: #fff;
}

.question-icon {
  transition: transform 0.3s ease;
}

.question-icon.rotate {
  transform: rotate(180deg);
}

.answer-text {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

.answer-text a {
  color: #0062ff;
  text-decoration: none;
}

.answer-text a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .PlatformIcon {
    width: 180px;
    height: 180px;
  }
  
  .PlatformIcon img {
    width: 60px;
    height: 60px;
  }
  
  .FeatureCard {
    position: relative;
    width: calc(100% - 32px);
    margin: 10px auto;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
  }
}

@media (max-width: 480px) {
  .PlatformIcon {
    width: 160px;
    height: 160px;
    padding: 12px;
  }
  
  .PlatformIcon img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 375px) {
  .PlatformIcon {
    width: 140px;
    height: 140px;
    padding: 8px;
  }
  
  .PlatformIcon img {
    width: 45px;
    height: 45px;
  }
} 
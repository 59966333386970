/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: rgba(82, 83, 87, 0.15);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(82, 83, 87, 0.5);
  border-radius: 4px;
  transition: background 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(82, 83, 87, 0.7);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(82, 83, 87, 0.5) rgba(82, 83, 87, 0.15);
} 
:root {
  --color--page-color: #000000;
  --main-font: Inter, sans-serif;
  --color--heading-text-color: #fff;
  --color--paragraph-text-color: #b4bcd0;
  --color--button-color: #007fff;
  --color--elements-stroke: rgba(255, 255, 255, 0.06);
  --color--elements-bg: rgba(255, 255, 255, 0.03);
  --color--hoved-color: rgba(255, 255, 255, 0.08);
  --color--acent-color: #007fff;
  --color--acent-color-dim: rgba(0, 127, 255, 0.1);
  --color--other-section-color: #17191c;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-users-userformpagewrap {
  flex-direction: column;
  justify-content: center; /* Centers content vertically within the parent */
  align-items: center; /* Centers content horizontally */
  max-width: 340px;
  height: 90vh;
  margin: 0 auto;
  display: flex;
}

.w-users-userformheader {
  text-align: center;
}

.w-commerce-commercecheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  height: 38px;
  min-height: 30px;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon,
.w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus,
.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus,
.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus,
.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem,
.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commerceaddtocartform {
  margin: 0 0 15px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  margin-bottom: 10px;
  display: flex;
}

.w-commerce-commerceaddtocartoptionpill {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 10px;
  padding: 8px 15px;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 60px;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  margin-top: 10px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  background-color: #ddd;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceaddtocarterror {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
}

.w-users-userloginformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformbutton {
  text-align: center;
  width: 100%;
  gap: 8px;
  margin-top: 15px;
}

.w-users-userformbutton .icon {
  width: 30px;
  height: 30px;
}

.separator {
  font-size: 14px;
  margin-top: 15px;
  color: #bababa;
  align-self: center;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* Smaller font size */
  padding: 6px 12px; /* Smaller padding for a compact look */
  margin-top: 20px; /* Add spacing above the back button */
}

.beta-text {
  color: grey;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
}

.center-hero-link {
  text-decoration: none; /* Removes underline by default */
  color: grey; /* Sets text color */
  font-size: inherit; /* Ensures font matches parent */
  cursor: pointer; /* Pointer cursor for better UX */
}

.center-hero-link:hover {
  text-decoration: none; /* Ensures no underline on hover */
  color: darkgrey; /* Optional hover effect */
}

.w-users-userformfooter {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.w-users-userformerrorstate {
  margin-left: 20px;
  margin-right: 20px;
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0%;
}

.w-commerce-commerceorderconfirmationcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem,
.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper,
.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-users-userresetpasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformsuccessstate {
  display: none;
}

.w-users-usersignupformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-users-usersignupverificationmessage {
  display: none;
}

.w-users-userupdatepasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-useraccountwrapper {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-users-blockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding: 20px;
}

.w-users-useraccountformsavebutton {
  text-align: center;
  margin-right: 8px;
}

.w-users-useraccountformcancelbutton {
  text-align: center;
  color: #333;
  background-color: #d3d3d3;
}

.w-users-blockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-users-useraccountsubscriptionlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  overflow: auto;
}

.w-users-useraccountsubscriptionlistitem {
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.w-commerce-commercecartproductname {
  font-weight: 700;
}

.w-users-gridrow {
  grid-column-gap: 8px;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  display: grid;
}

.w-users-flexcolumn {
  flex-direction: column;
  display: flex;
}

.w-users-useraccountsubscriptioncancelbutton {
  color: #3898ec;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }

  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput,
  .w-commerce-commercecheckoutshippingfullname,
  .w-commerce-commercecheckoutshippingstreetaddress,
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity,
  .w-commerce-commercecheckoutshippingstateprovince,
  .w-commerce-commercecheckoutshippingzippostalcode,
  .w-commerce-commercecheckoutshippingcountryselector,
  .w-commerce-commercecheckoutcardnumber,
  .w-commerce-commercecheckoutcardexpirationdate,
  .w-commerce-commercecheckoutcardsecuritycode,
  .w-commerce-commercecheckoutbillingfullname,
  .w-commerce-commercecheckoutbillingstreetaddress,
  .w-commerce-commercecheckoutbillingstreetaddressoptional,
  .w-commerce-commercecheckoutbillingcity,
  .w-commerce-commercecheckoutbillingstateprovince,
  .w-commerce-commercecheckoutbillingzippostalcode,
  .w-commerce-commercecheckoutbillingcountryselector,
  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  background-color: var(--color--page-color);
  font-family: var(--main-font);
  color: var(--color--heading-text-color);
  font-size: 14px;
  line-height: 1.2;
}

h1 {
  font-size: 62px;
  font-weight: 500;
  line-height: 1.1;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 42px;
  font-weight: 500;
  line-height: 1.2;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
}

p {
  font-family: var(--main-font);
  color: var(--color--paragraph-text-color);
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

a {
  color: var(--color--paragraph-text-color);
  text-decoration: none;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  color: var(--color--paragraph-text-color);
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
}

img {
  max-width: 100%;
  display: inline-block;
}

blockquote {
  border-left: 2px solid var(--color--button-color);
  text-transform: none;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px 0 40px 24px;
  font-size: 24px;
  font-style: italic;
  line-height: 1.7;
}

figure {
  margin-bottom: 10px;
}

figcaption {
  text-align: center;
  margin-top: 5px;
  font-size: 15px;
}

.white-colors-show-case {
  background-color: #fff;
  border-radius: 300px;
  width: 54px;
  height: 54px;
  box-shadow: 0 0 9px #00000047;
}

.white-colors-show-case.black {
  box-shadow: none;
  background-color: #7d7d7d;
}

.yellow-color-show-case {
  background-color: var(--color--button-color);
  border-radius: 300px;
  width: 54px;
  height: 54px;
}

.color-container {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.center-top {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.black-background {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
}

.color-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  display: flex;
}

._4-col-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.black-background-padding {
  padding: 40px;
}

.container {
  z-index: 2;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 3em 2em;
  position: relative;
}

.container.no-paddings {
  padding-top: 0;
  padding-bottom: 0;
  position: static;
}

.container.nav-container-wrapper {
  width: 100%;
  padding: 18px 14px 18px 8px;
}

._100width {
  width: 100%;
}

.border-box {
  border: 1px solid #cdcdcd;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.button {
  background-color: var(--color--button-color);
  transition: all 0.4s ease, transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #fff;
  text-align: center;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 30px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 127, 255, 0.3), inset 0 2px 10px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.button:hover {
  transform: scale(1.04);
  box-shadow: 0 2px 15px rgba(102, 179, 255, 0.4), inset 0 2px 10px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.9);
}

.button.white-button {
  box-shadow: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  color: var(--color--elements-bg);
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #d2d2d2;
}

.button.white-button:hover {
  color: var(--color--heading-text-color);
  background-color: #ffffff29;
}

.button.secondary {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--color--hoved-color);
  color: var(--color--heading-text-color);
  border: 0.5px solid #ffffff1a;
  border-bottom-style: none;
  box-shadow: inset 0 2.5px 0 -2px #ffffff1a;
}

.button.third {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--color--hoved-color);
  color: var(--color--heading-text-color);
  border: 0.5px solid #ffffff1a;
  border-bottom-style: none;
  box-shadow: inset 0 2.5px 0 -2px #ffffff1a;
  pointer-events: none; /* Disable interaction */
  opacity: 0.5; /* Make it visually disabled */
  transform: none; /* Prevent scale changes */
}

/* Specific styles for the third button */
.button.third:nth-of-type(3) {
  pointer-events: auto; /* Enable interaction */
  opacity: 1; /* Make it fully visible */
}

.style-guide-box-holder {
  border: 2px solid var(--color--elements-stroke);
  background-color: var(--color--page-color);
  padding: 20px;
}

.style-guide-box-container {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  display: flex;
}

.colors-buttons {
  grid-column-gap: 90px;
  align-items: center;
  display: flex;
}

.section {
  position: relative;
}

.section.overflow-hidden {
  overflow: hidden;
}

.section.extra-paddings {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.section.contact-v1 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.section.contact-v3 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button-container {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  border: 2px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  display: flex;
}

._8-col-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

._6-col-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.cols-text {
  font-size: 14px;
  font-weight: 400;
}

.paragraph-xl {
  font-size: 20px;
  line-height: 1.5;
}

.navbar {
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  background-color: #0a0c104d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}

.navbar-holder {
  width: 100%;
}

.navbar-container,
.nav-menu-link-holder {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.nav-menu-link-container {
  flex: 1;
  justify-content: center;
  display: flex;
}

.brand-image {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}

.nav-menu {
  padding-top: 3;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 18px;
  display: flex;
}

.nav-links {
  grid-column-gap: 29px;
  grid-row-gap: 29px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nav-links a {
  padding: 5px 0px;
}

.nav-link {
  color: #ffffff9e;
  border-bottom: 3px solid #fff0;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  transition: color 0.6s;
  display: flex;
  bottom: -1px;
}

.nav-link:hover {
  color: var(--color--acent-color);
}

.nav-link.w--current {
  color: var(--color--heading-text-color);
  font-weight: 600;
}

.footer-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2em;
  display: flex;
}

.footer-brand {
  grid-row-gap: 18px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  display: flex;
}

.footer-content {
  grid-column-gap: 70px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: auto auto 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-block {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.title-small {
  color: var(--color--heading-text-color);
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}

.footer-divider {
  border-top: 1px solid var(--color--elements-stroke);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  padding-top: 40px;
  display: flex;
}

.footer-copyright-center {
  text-transform: uppercase;
  font-size: 15px;
}

.footer-brand-image {
  width: 160px;
}

.white-link {
  color: var(--color--heading-text-color);
  font-weight: 500;
  text-decoration: underline;
  transition: color 0.375s;
}

.white-link:hover {
  color: #a1a1a1;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.title.medium {
  font-weight: 500;
}

.whitetext {
  color: #fff;
}

.center-text {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.featured-logo-wrapper {
  width: 110px;
}

.title-container {
  max-width: 762px;
}

.paragraph-holder {
  max-width: 465px;
}

.early-access-card {
  perspective: 1500px;
  width: 100%;
  min-width: 370px;
  position: relative;
}

.early-access-tag-holder {
  grid-column-gap: 10px;
  align-items: center;
  font-family: IBM Plex Sans Hebrew;
  font-size: 18px;
  display: flex;
}

.early-access-title-holder {
  max-width: 349px;
}

.power-feature-card-container {
  background-color: #0d0d0d;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.power-feature-heading {
  font-size: 20px;
}

.testimonial-slide {
  width: 100%;
  height: auto;
  margin-left: 15px;
  margin-right: 15px;
  left: -15px;
}

.hide {
  display: none;
}

.pricing-tag {
  text-transform: uppercase;
  border: 1px solid #fff3;
  border-radius: 100px;
  padding: 9px 16px;
  font-family: IBM Plex Sans Hebrew;
  font-size: 18px;
}

.pricing-header {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.pricing-header.left {
  text-align: left;
  align-items: flex-start;
}

.pricing-list-holder {
  border-top: 1px solid #414142;
  border-bottom: 1px solid #414142;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 55px;
  padding-bottom: 55px;
}

.checked-list-holder {
  grid-column-gap: 18px;
  align-items: center;
  padding-top: 12px;
  display: flex;
}

.pricing-footer-holder {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cta-form-holder {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 460px;
  display: flex;
  position: relative;
}

.cta-form-holder.no-paddings {
  padding-top: 13px;
  padding-bottom: 13px;
}

.text-field {
  z-index: 1;
  border: 1px solid var(--color--elements-stroke);
  color: #fff;
  background-color: #333;
  border-radius: 8px;
  width: 100%;
  height: 51px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  transition: border-color 0.45s, box-shadow 0.425s;
  position: relative;
  box-shadow: 0 2px 8px #0003, inset 0 0.5px 0.5px #fff3, inset 0 -0.5px 0.5px #0003;
}

.text-field:hover {
  border-color: #6c6c6c;
  box-shadow: 0 2px 8px #0003, inset 0 2px 2px #ffffff0f, inset 0 -0.5px 0.5px #0003;
}

.text-field:focus {
  border-color: var(--color--acent-color);
  box-shadow: 0 2px 8px #0003, inset 0 2px 5px #ee663d36, inset 0 -0.5px 0.5px #0003;
}

.text-field::placeholder {
  color: #7d7d7d;
}

.text-field.messages-field {
  min-width: 100%;
  max-width: 100%;
  height: 300px;
  min-height: 300px;
  max-height: 500px;
}

.text-field.white-text-field {
  background-color: var(--color--elements-bg);
  color: var(--color--heading-text-color);
}

.form {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.form-block {
  width: 100%;
  margin-bottom: 0;
}

.form-block._100width {
  width: 100%;
}

.submit-button {
  z-index: 1;
  background-color: var(--color--button-color);
  color: #fff;
  border-radius: 5px;
  height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 15px;
  font-weight: 400;
  transition: background-color 0.375s;
  position: absolute;
  right: 6px;
}

.submit-button:hover {
  background-color: var(--color--acent-color);
}

.form-holder {
  grid-row-gap: 13px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.thank-you-message {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  color: var(--color--button-color);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 24px;
  font-weight: 500;
  box-shadow: 0 4px 4px #0000003d, 0 0 14px #0000003d, 0 21px 36px #635bff1f;
}

.thank-you-message::placeholder {
  color: #7d7d7d;
  font-family: IBM Plex Sans Hebrew;
  font-size: 15px;
}

.error-message {
  color: #7d7d7d;
  text-align: center;
  background-color: #141414;
  border: 1px solid #aa5252;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 0;
  font-size: 16px;
  box-shadow: 0 4px 4px #0000003d, 0 0 14px #0000003d, 0 21px 36px #ff00041f;
}

.error-message::placeholder {
  color: #7d7d7d;
  font-family: IBM Plex Sans Hebrew;
  font-size: 15px;
}

.error-message.small {
  border-color: var(--color--acent-color);
  background-color: var(--color--elements-bg);
  color: var(--color--acent-color);
  margin: 14px 0;
  font-size: 15px;
  font-weight: 500;
}

.help-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
}

.help-container {
  border-right: 1px solid var(--color--hoved-color);
  width: 250px;
  min-width: 250px;
  padding-top: 80px;
  position: relative;
}

.help-content-holder {
  grid-row-gap: 30px;
  flex-direction: column;
  padding-top: 80px;
  display: flex;
}

.help-back-link-holder {
  grid-column-gap: 8px;
  color: #7d7d7d;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.4s;
  display: flex;
}

.help-back-link-holder:hover {
  color: #fff;
}

.help-icon-holder {
  width: 8px;
}

.help-icon {
  width: 100%;
}

.help-link-holder {
  grid-row-gap: 36px;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.help-link-container {
  z-index: 3;
  grid-column-gap: 16px;
  opacity: 1;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  left: 2px;
}

.help-link-container.w--current {
  border-right: 3px solid var(--color--acent-color);
  opacity: 1;
}

.help-link-icon-holder {
  width: 26px;
}

.help-link-icon {
  filter: none;
  width: 100%;
}

.figma-file-holder {
  margin-top: 40px;
  margin-bottom: 40px;
}

.figma-file-container {
  grid-column-gap: 32px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  opacity: 1;
  border-radius: 8px;
  align-items: flex-start;
  width: 100%;
  padding: 26px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 4px #0000000d, 1px 1px 14px #0000001a, 0 21px 36px #635bff21;
}

.figma-file-container.center {
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.figma-file-icon-holder {
  width: 41px;
  min-width: 41px;
  height: 41px;
}

.figla-file-icon {
  filter: none;
  width: 100%;
}

.figma-file-content-holder {
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.figma-header {
  font-size: 24px;
}

.full-width-image-holder {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.full-width-image {
  border-radius: 20px;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 85px;
}

.link {
  text-decoration: underline;
  transition: color 0.375s;
}

.link:hover {
  color: #afafaf;
}

.change-log-block-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 1fr;
}

.utility-page-wrap {
  background-color: var(--color--page-color);
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.utility-page-form {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

._404 {
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.brand {
  max-width: 140px;
}

.break-down-card-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.break-down-card {
  background-color: #0d0d0d;
  border: 2px solid #161616;
  border-radius: 24px;
  transition: border-color 0.425s;
  position: relative;
  overflow: hidden;
}

.break-down-card:hover {
  border-color: #353535;
}

.benefits-content {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.visable-image-content-holder {
  border-radius: 28px;
  width: 100%;
  overflow: hidden;
}

.visable-content {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: sticky;
  top: 35vh;
}

.value-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.value-team-images-holder {
  perspective: 1500px;
  width: 100%;
}

.team-gird {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.team {
  position: relative;
  overflow: hidden;
}

.blog-item {
  grid-row-gap: 18px;
  flex-direction: column;
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
}

.blog-item:hover {
  transform: translate(0, -12px);
}

.blog-item.with-background {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 8px;
  height: 100%;
  padding: 12px;
  box-shadow: inset 0 -20px 99.88px #c7d3ea0d;
}

.blog-item.with-background.hoizontal,
.blog-item.with-background.hoizontal-v2 {
  grid-column-gap: 30px;
  flex-direction: row;
  align-items: center;
}

.blog-grid-2x-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.blog-grid-2x-title {
  color: var(--color--heading-text-color);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
}

.blog-grid-twos-wrapper {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.blog-grid-3x {
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-grid-3x-image-holder {
  border-radius: 12px;
  width: 100%;
  height: 360px;
  overflow: hidden;
}

.blog-grid-3x-image-holder.normal-height {
  height: auto;
}

.blog-details-content {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 710px;
  display: flex;
}

.blog-date {
  font-size: 14px;
  font-weight: 400;
}

.blog-name-details {
  grid-column-gap: 0px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blog-details---title {
  font-size: 50px;
  font-weight: 600;
}

.share-blog-icons {
  grid-column-gap: 19px;
  grid-row-gap: 19px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.share-social-media-icon-holder {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  opacity: 0.3;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  transition: opacity 0.3s, transform 0.375s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
}

.share-social-media-icon-holder:hover {
  opacity: 1;
  transform: scale(1.2);
}

.share-icon {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  width: 24px;
}

.blog-details-holder {
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
}

.blog-details-main-image-holder {
  border-radius: 14px;
  width: 70%;
  margin: 60px auto;
  overflow: hidden;
}

.blog-details-main-image {
  width: 100%;
}

.category {
  color: var(--color--paragraph-text-color);
  font-size: 14px;
  font-weight: 400;
}

.category.white-text {
  color: var(--color--button-color);
}

.menu-cart-holder {
  grid-column-gap: 20px;
  align-items: center;
  display: flex;
}

.cart-button {
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  background-color: #3898ec00;
  align-items: flex-start;
  padding: 0;
}

.cart-header {
  border-bottom-color: var(--color--elements-stroke);
  margin-bottom: 12px;
  padding: 0 0 20px;
}

.cart-list {
  min-height: 410px;
  max-height: 410px;
  padding: 0;
}

.cart-hero {
  grid-column-gap: 12px;
  align-items: center;
  display: flex;
}

.remove-button {
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  inset: 0% 0% auto auto;
}

.cart-item {
  border-bottom: 1px solid var(--color--elements-stroke);
  flex-direction: column;
  margin-bottom: 12px;
  padding-top: 0;
  padding-bottom: 12px;
  position: relative;
}

.cart-item:last-child {
  border-bottom-style: none;
}

.price-and-quantity {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  display: flex;
}

.remove-button-x {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--acent-color);
  background-image: url("../images/X-Icon.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 40px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
}

.remove-button-x.grey {
  background-color: var(--color--button-color);
  opacity: 0.5;
}

.product-name {
  font-size: 22px;
  font-weight: 500;
}

.product-details-holder {
  grid-row-gap: 4px;
  flex-direction: column;
  max-width: 200px;
  display: flex;
}

.product-thumbnail {
  border-radius: 8px;
  width: 72px;
  height: auto;
}

.product-cart-price {
  font-size: 21px;
  font-weight: 400;
}

.cart-quantity-menu {
  border-style: solid;
  border-color: var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 4px;
  margin-bottom: 0;
}

.quantity-holder {
  grid-column-gap: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-footer {
  border-top-color: var(--color--paragraph-text-color);
  padding: 24px 0 0;
}

.sub-total {
  font-size: 24px;
}

.no-margins {
  margin-bottom: 0;
}

.checkout-form {
  background-color: var(--color--page-color);
}

.block-header {
  background-color: #fff0;
  border-style: none;
}

.block-content {
  background-color: #fff0;
  border: 1px #000;
}

.customer-info {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
}

.checkout-input {
  border-width: 1.5px;
  border-color: var(--color--elements-stroke);
  background-color: var(--color--hoved-color);
  color: var(--color--button-color);
  border-radius: 6px;
  height: 51px;
  font-size: 16px;
}

.field-label {
  color: var(--color--paragraph-text-color);
  font-size: 16px;
  font-weight: 400;
}

.line-item {
  grid-row-gap: 4px;
  border-bottom: 1px solid #dadada;
  flex-direction: column;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.line-item.no-border {
  border-bottom-style: none;
}

.sub-total-checkout {
  color: var(--color--other-section-color);
  font-size: 18px;
}

.total-checkout {
  font-size: 32px;
  font-weight: 500;
}

.second-cta-container {
  background-color: #0d0d0d;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  overflow: hidden;
}

.empty-state {
  border-radius: 15px;
  font-size: 24px;
  font-weight: 500;
}

.footer-heading {
  color: var(--color--heading-text-color);
  font-size: 40px;
  font-weight: 600;
  display: block;
}

.dropdown-toggle {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--color--acent-color-dim);
  background-color: var(--color--acent-color-dim);
  color: var(--color--button-color);
  border-radius: 40px;
  align-items: center;
  padding: 10px 15px 7px;
  display: flex;
}

.cart-number {
  color: #727272;
  background-color: #fff0;
  justify-content: center;
  align-items: center;
  min-width: 0;
  height: 100%;
  margin-left: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  transition: border-color 0.6s;
  display: flex;
  bottom: -1px;
}

.cart-number.w--current {
  color: #fff;
  font-weight: 600;
}

.cart-number-holder {
  display: flex;
}

.hero-section-center-holder {
  perspective: 1500px;
  perspective-origin: 80% 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  display: flex;
}

.hero-section-center-holder.contact-v2 {
  z-index: 1;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  position: relative;
}

.hero-section-center-holder.contact-v1 {
  align-items: center;
}

.hero-center-text {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 830px;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
}

.center-hero-paragraph-holder {
  text-align: center;
  max-width: 620px;
}

.hero-button-holder {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  display: flex;
  position: relative;
}

.hero-app-holder {
  border-radius: 13px;
  width: 100%;
  margin-top: 80px;
  position: relative;
}

.cart {
  margin-right: 0;
}

.company-logo-holder {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.company-logo-container {
  flex-direction: row;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.company-logo-wrapper {
  flex: none;
  height: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.company-logo {
  opacity: 0.6;
  height: 100%;
}

.graident-for-logos {
  background-image: linear-gradient(270deg, #06020a00, var(--color--page-color));
  width: 120px;
  height: 100%;
  position: absolute;
  inset: 0% auto 0% 0%;
}

.graident-for-logos.right {
  background-image: linear-gradient(45deg, #06020a00, var(--color--page-color));
  inset: 0% 0% 0% auto;
}

.trusted-by {
  z-index: 0;
  padding-top: 70px;
  padding-bottom: 96px;
  position: relative;
}

.section-center-text {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.section-title {
  max-width: 640px;
}

.section-title.xl {
  max-width: 800px;
}

.section-title.l {
  max-width: 750px;
}

.section-paragraph {
  max-width: 520px;
}

.feature-icon-holder {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: border-color 0.375s, background-color 0.325s;
  display: flex;
}

.feature-icon-holder:hover {
  border-color: var(--color--button-color);
  background-color: var(--color--hoved-color);
}

.section-paddings {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-tag {
  background-color: var(--color--acent-color-dim);
  color: var(--color--heading-text-color);
  text-align: left;
  text-transform: uppercase;
  border-radius: 190px;
  padding: 6px 25px 6px 26px;
  font-weight: 500;
}

.new-features-holder {
  grid-column-gap: 90px;
  grid-row-gap: 90px;
  flex-direction: column;
  padding-top: 30px;
  display: flex;
}

.feature-grid-content-holder {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
}

.check-icon-holder {
  background-color: var(--color--acent-color);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  display: flex;
}

.check-icon-holder.outlined {
  border: 1px solid var(--color--acent-color);
  background-color: var(--color--acent-color-dim);
}

.check-icon {
  width: 11px;
}

.check-item-text {
  color: var(--color--paragraph-text-color);
  margin-bottom: 0;
  font-weight: 400;
}

.list-item {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.background-image {
  mix-blend-mode: lighten;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.testimonal-slider {
  background-color: #ddd0;
  height: auto;
  margin-top: 75px;
}

.testimonal-mask {
  width: 400px;
  height: auto;
  overflow: visible;
}

.testimonal-slide {
  background-color: var(--color--elements-bg);
  border-top: 0.5px solid #ffffff1c;
  border-bottom: 1px #ffffff1c;
  border-left: 1px solid #ffffff1c;
  border-right: 0.5px solid #ffffff1c;
  border-radius: 6px;
  height: auto;
  margin: 10px;
  padding: 30px;
  box-shadow: inset 0 1px 1px #c7d3ea1f, inset 0 -20px 99px #c7d3ea0d;
}

.arrow-holder {
  border: 1.5px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  transition: border-color 0.375s;
  display: flex;
}

.arrow-holder:hover {
  border-color: var(--color--acent-color);
}

.arrow-icon {
  width: 7px;
}

.arrow-icon.right-side {
  margin-left: 1px;
}

.arrow-icon.rotate {
  transform: rotate(-180deg);
}

.left-arrow {
  z-index: 10;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  inset: auto auto -60px 0%;
}

.right-arrow {
  z-index: 10;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  inset: auto auto -60px 60px;
}

.testimonial-text {
  color: var(--color--paragraph-text-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
}

.testimonal-info {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  display: flex;
}

.user-image {
  border-radius: 200px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.user-name {
  font-size: 18px;
  font-weight: 500;
}

.user-job-position {
  opacity: 0.69;
  color: var(--color--paragraph-text-color);
  font-size: 15px;
  font-weight: 400;
}

.blog-title-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}

.blog-arrow {
  width: 12px;
  min-width: 12px;
  margin-top: 7px;
}

.questions-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 42px;
  display: flex;
}

.questions-container {
  background-color: var(--color--elements-bg);
  cursor: pointer;
  border: 1px solid #d1aad70f;
  border-bottom-style: none;
  border-radius: 12px;
  flex-direction: column;
  padding: 20px;
  transition: all 0.325s ease;
  display: flex;
  box-shadow: inset 0 1px 1px #c7d3ea1f, inset 0 -20px 100px #c7d3ea0d;
}

.question {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.question-icon {
  width: 24px;
  min-width: 24px;
  transition: transform 0.3s ease;
}

.question-icon.rotate {
  transform: rotate(45deg);
}

.question-text {
  font-size: 18px;
  font-weight: 500;
}

.answer-holder {
  justify-content: flex-start;
  max-width: 750px;
  display: flex;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.answer-text {
  color: var(--color--paragraph-text-color);
  max-width: 93%;
  padding-top: 3px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

.questions-cta {
  z-index: 1;
  grid-row-gap: 84px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px;
  display: flex;
  position: relative;
}

.heading-and-supporting-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 410px;
  display: flex;
}

.small-cta-heading {
  font-size: 20px;
  font-weight: 500;
}

.paragraph-l {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
}

.colored-block {
  z-index: 10;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.cta-wrapper {
  position: relative;
  overflow: hidden;
}

.feature-slider {
  height: auto;
  margin-right: 30px;
}

.slider-fade-holder {
  margin-top: -2em;
  position: relative;
}

.hero {
  margin-top: 30px;
}

.team-user {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  padding: 24px;
}

.hero-blog-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
}

.blog-featured-holder {
  width: 100%;
  margin-top: 80px;
}

.sign-up-letter-holder {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.sign-up-letter {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  padding: 24px;
  position: sticky;
  top: 160px;
}

.sign-up-letter-content {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.sign-up-title {
  font-size: 20px;
}

.blog-grid-holder {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1.5fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
}

.contact-form-holder {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 42px;
  display: flex;
}

.contact-form-holder.v3 {
  align-items: flex-start;
  max-width: 750px;
}

.form-fields-holder {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  display: flex;
}

.form-fields-holder.no-gab {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.contact-us-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-form-block {
  width: 690px;
  max-width: 690px;
  margin-top: 15px;
}

.pricing-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.pricing-wrapper {
  width: 100%;
  padding-top: 70px;
}

.pricing {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  flex-direction: column;
  padding: 40px 32px 32px;
  display: flex;
}

.pricing.middle {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  border-style: solid solid none;
  border-width: 1px;
  border-color: var(--color--acent-color-dim) var(--color--acent-color-dim) var(--color--acent-color);
  background-image: linear-gradient(180deg, var(--color--acent-color-dim), var(--color--elements-bg) 38%);
  height: 110%;
}

.price {
  font-size: 40px;
  font-weight: 600;
}

.price-content {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.price-plan {
  font-size: 23px;
  font-weight: 600;
}

.price-type {
  color: var(--color--paragraph-text-color);
  text-align: left;
}

.pricing-button-holder {
  flex-direction: column;
  display: flex;
}

.pricing-details-holder {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  padding: 40px 32px;
  display: grid;
}

.pricing-add-to-cart {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-left: 1px solid var(--color--elements-stroke);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-left: 70px;
  display: flex;
}

.text-field-holder {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  margin-top: 14px;
  display: flex;
}

.add-to-card-button-holder {
  flex-direction: column;
  margin-top: 18px;
  display: flex;
}

.rich-text-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.cart-holder {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  background-image: radial-gradient(circle farthest-corner at 100% 100%, var(--color--acent-color-dim), #7f56d900 42%), radial-gradient(circle farthest-corner at 6% -34%, var(--color--acent-color-dim) 9%, #7f56d900 30%);
  border-radius: 12px;
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
  padding: 21px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.options-holder {
  flex-direction: column;
  display: flex;
}

.login-form-wrapper {
  z-index: 2;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  flex-direction: column;
  padding: 62px 104px 80px 104px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.login-form-wrapper.red {
  background-image: radial-gradient(circle at 100% 100%, #d9565833, #7f56d900 42%), radial-gradient(circle at 6% -34%, #ff000433 9%, #7f56d900 30%);
  border-color: #ff1c203b;
}

.page-wrapper {
  align-items: center;
  max-width: none;
}

.login-button-holder {
  margin-top: 24px;
}

.check-box-holder {
  margin-top: 12px;
}

.checkbox {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  width: 16px;
  height: 16px;
  margin-top: 1px;
}

.checkbox:hover {
  border-color: var(--color--button-color);
}

.checkbox.w--redirected-checked {
  border-color: var(--color--acent-color);
  background-color: var(--color--elements-bg);
  background-image: url("../images/Orange-Tick.svg");
  background-position: 50%;
  background-size: 7px;
}

.checkbox-field {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  display: flex;
}

.form-header-margins {
  margin-top: -40px;
  margin-bottom: 60px;
}

.user-account-wrapper {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  background-color: var(--color--page-color);
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.password-settings {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.password-buttons {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.checkbox-field-user-acc {
  margin-top: 12px;
  margin-bottom: 12px;
}

.user-account-form {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  place-items: center stretch;
  display: grid;
}

.subscription {
  padding-top: 17px;
  padding-bottom: 17px;
}

.image {
  border-radius: 4px;
}

.subscritpion-title {
  font-size: 18px;
  font-weight: 500;
}

.subscription-tilte-holder {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.subscription-cancel-button {
  color: var(--color--button-color);
  font-size: 18px;
  font-weight: 600;
}

.subscription-price {
  font-size: 20px;
  font-weight: 600;
}

.right {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: flex-end;
}

._404-title {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  display: flex;
}

.home-sale-section {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--hoved-color);
  border-radius: 0;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-sale-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
}

.screenshot-holder {
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  transition: transform 0.375s;
  display: flex;
}

.screenshot-holder:hover {
  transform: translate(0, -15px);
}

.page-screenshot {
  border: 1px solid var(--color--acent-color);
  border-radius: 5px;
  width: 100%;
}

.included-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 60px;
  display: grid;
}

.included-item {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 18px;
  display: flex;
  overflow: hidden;
}

.included-image {
  filter: hue-rotate(231deg);
  width: 100%;
}

.included-text {
  font-size: 20px;
  font-weight: 500;
}

.oversight-cta-holder {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.oversight-content-holder {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px;
  display: flex;
}

.oversight-logo {
  filter: none;
  width: 52px;
}

.oversight-title {
  font-size: 20px;
  font-weight: 500;
}

.oversight-paragraph {
  font-weight: 300;
}

.all-pages-gird {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 80px;
  display: grid;
}

.pages-holder {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--hoved-color);
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  transition: border-color 0.3s, background-color 0.375s;
  display: flex;
  overflow: hidden;
}

.pages-holder:hover {
  border-color: var(--color--button-color);
  background-color: var(--color--hoved-color);
}

.page-image {
  width: 100%;
}

.page-title {
  text-align: center;
  margin-top: 19px;
  margin-bottom: 19px;
  font-size: 20px;
  font-weight: 500;
}

.mega-menu-holder {
  z-index: 19;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  background-image: radial-gradient(circle at 100% 100%, #ff784f33, #7f56d900 42%), radial-gradient(circle at 6% -34%, #ff992433 9%, #7f56d900 30%);
  border-radius: 12px;
  flex-direction: column;
  padding: 0;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%);
}

.mega-menu-holder.w--open {
  background-color: var(--color--page-color);
  background-image: radial-gradient(circle farthest-corner at 100% 100%, var(--color--acent-color-dim), #7f56d900 42%), radial-gradient(circle farthest-corner at 6% -34%, var(--color--acent-color-dim) 9%, #7f56d900 30%);
}

.mega-menu-content {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.menu-title {
  font-size: 24px;
  font-weight: 500;
}

.menu-links-holder {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  padding-top: 12px;
  display: flex;
}

.grey-link {
  color: var(--color--paragraph-text-color);
  font-size: 16px;
  transition: color 0.4s;
}

.grey-link.w--current {
  color: var(--color--heading-text-color);
  font-weight: 600;
}

.menu-link-wrapper {
  grid-column-gap: 42px;
  grid-row-gap: 42px;
  display: flex;
}

.menu-link-wrapper.last {
  border-right-style: none;
}

.mega-menu-wrapper {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  justify-content: space-between;
  width: 640px;
  display: flex;
  overflow: hidden;
}

.mega-menu-wrapper-holder {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 30px;
  display: flex;
}

.divider-vertical {
  background-color: var(--color--elements-stroke);
  width: 1px;
  height: 70%;
  transform: translate(0, 30%);
}

.brand-holder {
  flex: none;
  margin-right: 40px;
}

.figure-caption {
  background-color: #fff;
  border-radius: 20px;
  max-width: 400px;
  margin-bottom: 12px;
  margin-left: 12px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  inset: auto auto 0% 0%;
}

.nav-container-wrapper {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 4px 8px #0000000d, 0 15px 15px #0000000d, 0 33px 20px #00000008, 0 58px 23px #00000003, 0 91px 26px #0000;
}

.dropshadow-background {
  z-index: 0;
  background-color: var(--color--elements-bg);
  border-radius: 13px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: absolute;
  inset: 0%;
  box-shadow: 0 244.4px 85.5px #00000008, 0 147.1px 51.5px #00000008, 0 97px 34px #00000008, 0 66.5px 23.3px #00000008, 0 46.3px 16.2px #00000008, 0 32.5px 11.4px #00000008, 0 22.7px 7.9px #00000008, 0 15.7px 5.5px #00000008;
}

.grid-feature-icon-holder {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.grid-feature-icon-holder.vertical {
  text-align: center;
}

.grid-feature-title-holder {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  display: flex;
}

.grid-feature-title-holder.vertical-way {
  flex-direction: column;
}

.grid-feature-icon {
  width: 22px;
  height: 18px;
}

.grid-feature-icon.rounded {
  border: 1px solid var(--color--acent-color);
  background-color: var(--color--acent-color-dim);
  border-radius: 50px;
  width: 42px;
  height: 42px;
  padding: 8px;
}

.grid-heading {
  font-weight: 500;
}

.grid-paragraph {
  opacity: 0.8;
  font-weight: 400;
  line-height: 1.3;
}

.grid-features-holder {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.dark-section-features {
  padding-top: 100px;
}

.left-tabs-holder {
  padding-top: 50px;
}

.dark-card-content {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.testimonal-logo {
  max-width: 100px;
}

.testimonal-info-wrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.cta-logo-holder {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.cta-logo-holder.contact-hero {
  margin-bottom: 24px;
}

.cta-logo {
  z-index: 1;
  width: 70px;
  position: relative;
}

.orange-dot {
  background-color: var(--color--heading-text-color);
  border-radius: 20px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -6px;
}

.cta-line {
  background-image: linear-gradient(135deg, #e0602400, var(--color--heading-text-color));
  opacity: 0.5;
  width: 4px;
  height: 110px;
  position: absolute;
  bottom: 70px;
}

.fact-title-holder {
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  color: var(--color--acent-color);
  text-align: center;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  padding: 40px 24px;
  font-size: 42px;
  font-weight: 700;
  display: flex;
}

.fact-secondary-title {
  color: var(--color--paragraph-text-color);
  font-size: 20px;
  font-weight: 500;
}

.fact-secondary-title.small-text {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
}

.feature-hero-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.best-features-content-holder {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.about-hero-wrapper {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 0.7fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.about-image {
  z-index: 3;
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: relative;
}

.about-us-text-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.about-us-paragraph {
  margin-bottom: 0;
  font-size: 18px;
}

.logo-about-image {
  width: 100%;
}

.about-us-image {
  border-radius: 12px;
  width: 100%;
  margin-top: 24px;
}

.our-team-gird {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.our-team-facts {
  justify-content: space-between;
  display: flex;
}

.our-team-image-holder {
  width: 100%;
}

.our-team-image {
  border-radius: 8px;
  width: 100%;
}

.job-position-list {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.job-position-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border: 1px solid var(--color--elements-stroke);
  background-color: var(--color--elements-bg);
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 2.75fr 1fr 1fr 1fr 0.25fr;
  grid-auto-columns: 1fr;
  place-items: center start;
  padding: 20px;
  transition: transform 0.275s, border-color 0.3s, background-color 0.275s;
  display: grid;
}

.job-position-item:hover {
  border-color: var(--color--acent-color);
  background-color: var(--color--hoved-color);
  transform: scale(1.01);
}

.job-position-title {
  font-size: 22px;
  font-weight: 500;
}

.job-position-title.small-text {
  color: var(--color--paragraph-text-color);
  font-size: 18px;
  font-weight: 400;
}

.job-arrow-holder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.job-arrow {
  width: 18px;
}

.blog-grid-2x {
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-list-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  display: flex;
}

.blog-list-thumnbail-holder {
  border-radius: 6px;
  width: 100%;
  max-width: 370px;
  overflow: hidden;
}

.rich-text-block {
  text-align: left;
}

.fixed-nav {
  z-index: 20;
  position: fixed;
  inset: 0% 0% auto;
}

.hero-description {
  text-align: center;
  max-width: 420px;
  margin-top: 8px;
  margin-bottom: 30px;
}

.contact-heading {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.orange-dot-contact {
  background-color: var(--color--heading-text-color);
  border-radius: 20px;
  width: 10px;
  height: 10px;
  position: static;
  top: -6px;
}

.contact-orange-dot-holder {
  align-items: center;
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
}

.orange-connect {
  background-color: var(--color--heading-text-color);
  opacity: 0.6;
  width: 20px;
  height: 3px;
}

.contact-cta-grid {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
}

.contact-images-grid-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.contact-images-grid {
  place-items: end;
  min-width: 700px;
}

.contact-image-holder {
  width: 100%;
  height: 100%;
}

.contact-image {
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
}

.button-holder-contact {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.small-tag {
  border: 1px solid var(--color--acent-color);
  background-color: var(--color--acent-color-dim);
  color: var(--color--acent-color);
  border-radius: 40px;
  padding: 6px 21px;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
}

.hero-app-light-holder {
  z-index: 0;
  position: absolute;
  inset: 0%;
}

.hero-app-light {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, #ffffff42, var(--color--page-color) 80%);
  opacity: 0.45;
  filter: blur(60px);
  border-radius: 0;
  width: 812px;
  height: 260px;
  position: relative;
  background-image: radial-gradient(circle, rgba(0, 127, 255, 0.15), transparent);
  opacity: 0.8;
  filter: blur(100px);
}

.hero-app-light._01 {
  opacity: 0.67;
  width: 598px;
  height: 172px;
  position: absolute;
  inset: -3% auto auto -12%;
}

.hero-app-light._02 {
  position: absolute;
  inset: 0% auto auto 16%;
}

.hero-app-light._30 {
  opacity: 0.65;
  position: absolute;
  inset: -6% -21% auto auto;
}

.home-hero-text-holder {
  max-width: 985px;
}

.gradient-title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#fff, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8px;
  font-size: 55px;
  font-weight: 500;
  line-height: 1;
}

.home-hero-button-holder {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
}

.badge {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-image: radial-gradient(circle at 50% 200%, #343539ac, #17181a8b 61%), radial-gradient(circle at 50% -60%, #343539a5, #17181a95 75%);
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 6px;
  font-size: 15px;
  font-weight: 400;
  display: flex;
}

.hero-section-holder {
  width: 100%;
  height: 100%;
  display: flex;
}

.hero-dashboard-image {
  border: 0.5px solid #ffffff47;
  border-bottom-style: none;
  border-radius: 20px;
  width: 100%;
}

.hero-dashboard-image.smaller {
  width: 80%;
}

.ethereal-button {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  color: #0e0f11;
  background-color: #fff;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  font-family: Lato, sans-serif;
  font-size: 15px;
  transition: background-color 0.375s, transform 0.45s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 127, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ethereal-button:hover {
  background-color: #dfdfdf;
  transform: scale(1.05);
}

.ethereal-button.ease-accent {
  color: #fff;
  background-image: linear-gradient(0deg, #007fff, #0066cc);
  box-shadow: 0 2px 10px rgba(0, 127, 255, 0.3);
}

.center-hero {
  z-index: 1;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.hero-dashboard-holder {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.paragraph {
  color: #b4bcd0;
  font-size: 20px;
}

.hero-paragraph-holder {
  max-width: 600px;
}

.button-arrow-icon {
  width: 12px;
}

.badge-icon {
  width: 6px;
}

.navbar-link {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: #1d2023;
  border: 0.5px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 7px 0 7px 18px;
  display: flex;
}

.hero-light-wrapper {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: absolute;
  inset: 0%;
}

.hero-light {
  opacity: 0.72;
  filter: blur(20px);
  background-image: radial-gradient(circle, #333439, #0e0f11);
  border-radius: 2000px;
  width: 100%;
  height: 1000px;
  position: absolute;
  top: auto;
  bottom: 0%;
  background-image: radial-gradient(circle, rgba(0, 127, 255, 0.15), transparent);
  opacity: 0.8;
  filter: blur(100px);
}

.bento-grid-01 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
}

.bento-grid-01.ease-reverse {
  grid-template-columns: 1fr 2fr;
}

.bento-grid-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  margin-top: 52px;
  display: flex;
}

.bento-grid-text-holder {
  max-width: 440px;
  padding-bottom: 60px;
  padding-left: 60px;
}

.bento-grid-text-holder.ease-center-text {
  text-align: center;
  padding: 0 0 60px;
}

.bento-grid-text {
  color: #fff;
  font-size: 42px;
  line-height: 1.2;
}

.bento-grid-image,
.bento-grid-image-holder {
  width: 100%;
}

.bento-grid-image-holder.ease-outside {
  margin-top: -50px;
  margin-bottom: 50px;
}

.bento-grid-container {
  background-image: radial-gradient(circle at 50% 200%, #34353928, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353975, #17181a00 75%);
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 14px;
  flex-flow: column;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.pricing-wrapper {
  display: block;
}

.pricing-wrapper2 {
  display: none;
}

.bento-grid-container.ease-center-text {
  justify-content: space-between;
  align-items: center;
}

.bento-grid-container.ease-outside {
  margin-top: 50px;
  overflow: visible;
}

.feature-list {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.feature-text {
  color: var(--color--paragraph-text-color);
  font-size: 18px;
}

.feature-holder {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.feature-holder.large-gap {
  grid-column-gap: 120px;
  grid-row-gap: 120px;
}

.feature-image {
  border-radius: 12px;
  width: 100%;
}

.feature-list-holder {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.feature-list-holder.ease-margins-child {
  margin-top: 24px;
  margin-bottom: 24px;
}

.paragraph-s {
  color: #999;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 400;
}

.paragraph-s.ease-grey-text {
  color: #999;
}

.feature-image-holder {
  width: 100%;
}

.feature-check-holder {
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.feature-half-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  max-width: 600px;
  display: flex;
}

.feature-content-grid {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 40px;
  margin-bottom: 42px;
  display: grid;
}

.feature-heading-text {
  color: #fff;
  font-size: 42px;
  font-weight: 400;
  line-height: 1.2;
}

.feature-heading-content {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.home-check-icon {
  width: 12px;
}

.features-grid-3x {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 12px;
}

.feature-item-center {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  text-align: center;
  background-color: #17181a;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 14px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 34px;
  display: flex;
  box-shadow: inset 0 -20px 99px #c7d3ea0d;
}

.fetaure-icon-holder {
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  display: flex;
}

.feature-icon {
  width: 34px;
}

.feature-heading-small {
  color: #fff;
  font-size: 22px;
}

.feature-content {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  flex-flow: column;
  display: flex;
}

.feature-content.ease-less-gab {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: center;
  align-items: flex-start;
}

.short-testimonial-person {
  color: #999;
}

.short-testimonial-logo {
  height: 27px;
}

.short-testimonial-text {
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
}

.feature-grid {
  grid-column-gap: 110px;
  grid-row-gap: 110px;
  grid-template-rows: auto;
  grid-template-columns: 0.75fr 1fr;
  grid-auto-columns: 1fr;
  place-items: center;
  display: grid;
}

.feature-grid.reverse {
  grid-template-columns: 1fr 1fr;
}

.short-testimonial-holder {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 50px 34px;
  display: flex;
}

.short-testimonial-content-holder {
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  flex-flow: column;
  display: flex;
}

.feature-grid-2x {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: flex-start;
  display: grid;
}

.facts {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-bottom: 1px solid #ffffff1a;
  flex-flow: column;
  padding-bottom: 20px;
  display: flex;
}

.number-fact {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(315deg, #999, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.1;
}

.eas-fact-sub-heading {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 22px;
}

.second-title {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(315deg, #999, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  font-weight: 400;
  line-height: 1.2;
}

.cta-button-holder {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  display: flex;
}

.cta-left-right-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  flex-flow: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
  display: grid;
}

.cta-heading-holder {
  max-width: 510px;
}

.cta-heading-holder.ease-larger {
  max-width: 700px;
}

.cta-holder-06 {
  background-color: #000000;
  background-image: radial-gradient(circle at 50% 200%, #34353928, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353975, #17181a00 75%);
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  box-shadow: inset 0 -20px 99px 1px #c7d3ea0d;
}

.iphone-image {
  z-index: 1;
  width: 100%;
  position: relative;
}

.title-2 {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(315deg, #999, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

.cta-form {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.iphone-screen-image {
  object-fit: cover;
  border-radius: 40px;
  width: 100%;
  height: 100%;
}

.iphone-screen-image-holder {
  padding: 4%;
  position: absolute;
  inset: 0%;
}

.hero-dashboard-image-2 {
  border: 1px solid #ffffff2e;
  border-radius: 20px;
  width: 100%;
}

.hero-dashboard-image-2.ease-smaller {
  width: 80%;
}

.iphone-image-wrapper-cta {
  justify-content: flex-end;
  align-items: flex-end;
  width: 250px;
  display: flex;
  position: absolute;
  inset: auto 89px 81px auto;
}

.ethereal-text-field {
  color: #fff;
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-radius: 6px;
  height: 38px;
  margin-bottom: 0;
  line-height: 1.2;
}

.ethereal-text-field:focus {
  border-color: #846fff;
}

.ethereal-text-field::placeholder {
  color: #919191;
}

.ethereal-text-field.ease-hero-text-field {
  border-radius: 20px;
  height: 36px;
}

.ease-cta-holder-03 {
  background-color: #0e0f11;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 40px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 720px;
  padding-top: 56px;
  padding-bottom: 56px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 1px 1px #c7d3ea1f, inset 0 -20px 99px 1px #c7d3ea0d;
}

.ease-center-hero-2 {
  z-index: 1;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.ease-iphone-holder {
  width: 300px;
  position: relative;
}

.ease-form-success-message {
  color: #fff;
  background-color: #846fff;
  border: 1px solid #653cd6;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
}

.ease-form-error-message {
  background-color: #262629;
  border: 1px solid #ff7171;
  border-radius: 6px;
  font-size: 16px;
}

.ease-hero-form {
  width: 310px;
}

.ease-cta-dashboard-holder {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 120px;
  display: flex;
  position: relative;
}

.ease-submit-button-form {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  color: #0e0f11;
  background-color: #fff;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  font-size: 15px;
  display: flex;
  position: absolute;
}

.ease-badge-2 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-bottom-style: none;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 6px;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 400;
  display: flex;
}

.ease-hero-dashborad-cutout {
  margin-top: 1px;
  z-index: 1;
  border-radius: 20px;
  flex: none;
  width: 70vw;
  min-width: 70vw;
  position: relative;
  overflow: hidden;
}

.ease-hero-section-grid {
  grid-column-gap: 130px;
  grid-row-gap: 130px;
  text-align: center;
  flex-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.ease-hero-section-left-side {
  z-index: 2;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  text-align: left;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 460px;
  display: flex;
  position: relative;
}

.ease-bento-grid-holder-2 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  display: flex;
}

.ease-bento-grid-heading-03 {
  color: #fff;
  font-size: 24px;
}

.ease-bento-grid-text-wrapper {
  justify-content: center;
  align-items: center;
  padding: 40px;
  display: flex;
}

.ease-bento-grid-04 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.ease-bento-grid-content {
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.ease-bento-grid-content.ease-center-about-image {
  width: 40%;
  position: absolute;
}

.ease-bento-grid-heading-01 {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 52px;
  line-height: 1.2;
}

.ease-bento-grid-02 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
}

.ease-features-grid {
  grid-column-gap: 50px;
  grid-row-gap: 80px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-width: auto;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.ease-feature-item {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.ease-feature-item.ease-center-text {
  text-align: center;
  justify-content: flex-start;
  align-items: center;
}

.dashboard-holder {
  width: 100%;
}

.dashboard-image {
  border: 1px solid #ffffff1f;
  border-bottom-style: none;
  border-radius: 12px;
  width: 100%;
}

.ease-hero-section-holder-2 {
  flex-flow: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.text-field-second {
  color: #fff;
  background-color: #262629;
  border: 1px solid #ffffff1a;
  border-radius: 6px;
  height: 38px;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  line-height: 1.2;
}

.text-field-second:focus {
  border-color: #846fff;
}

.text-field-second::placeholder {
  color: #919191;
}

.text-field-second.ease-hero-text-field {
  border-radius: 20px;
  height: 36px;
}

.center-hero-3 {
  z-index: 1;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: Lato, sans-serif;
  display: flex;
  position: relative;
}

.iphone-image-wrapper {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: absolute;
  inset: auto 0% 0% auto;
}

.bento-grid-content-wrapper {
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  position: absolute;
  inset: 0%;
}

.bento-grid-heading-02 {
  color: #fff;
  font-size: 32px;
}

.bento-grid-5 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
}

.tab-link-description {
  color: #999;
  font-size: 17px;
}

.tab-pane-tab-1 {
  width: 100%;
}

.tab-link-heading {
  color: #fff;
  font-size: 24px;
}

.eas-tab-link-01 {
  background-color: #17181a;
  border: 1px solid #fff0;
  border-bottom-style: none;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 30px;
  transition: border-color 0.375s, background-color 0.425s;
}

.eas-tab-link-01.w--current {
  background-color: #262629;
  border-width: 1px;
  border-color: #ffffff1a;
}

.tab-image,
.tab-image-holder {
  width: 100%;
}

.tabs-01 {
  width: 100%;
  margin-top: 32px;
  display: flex;
}

.tabs-content {
  flex: 1;
}

.tab-link-content {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.tab-menu-01 {
  flex-flow: column;
  max-width: 470px;
  margin-right: 30px;
  display: flex;
}

.feature-dashboard-image {
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  width: 100%;
  position: relative;
}

.feature-dashboard-holder {
  border: 1px solid #ffffff1a;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 25px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.feature-text-2 {
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 18px;
}

.feature-bg-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.cart-wrapper {
  background-color: var(--color--page-color);
  border-radius: 11px;
}

.bold-text {
  color: var(--color--heading-text-color);
  font-size: 85px;
  font-style: normal;
}

.welcome {
  font-size: 2.5rem; /* Adjust size to match h1 */
  font-weight: bold; /* Bold text */
  margin: 0; /* Remove default margins */
  line-height: 1.2; /* Adjust line height */
  color: #333; /* Set text color */
  text-align: left; /* Align text left, can be center if preferred */
}

.paragraph-2 {
  margin-top: -9px;
  margin-bottom: -21px;
  padding-top: 0;
}

.container-2 {
  margin-left: 0;
  display: flex;
}

.heading {
  margin-left: 5px;
}

@media screen and (min-width: 1440px) {
  .featured-logo-wrapper {
    width: 130px;
  }

  .contact-images-grid {
    min-width: 800px;
  }

  .second-title {
    box-sizing: border-box;
    aspect-ratio: auto;
    object-fit: fill;
  }
}

@media screen and (max-width: 991px) {
  .hero {
    margin-top: 0px;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 20px;
  }

  .container.nav-container-wrapper {
    width: 90%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .section.contact-v1 {
    padding-top: 0;
    padding-bottom: 0;
  }

  ._8-col-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .navbar {
    height: 57px;
  }

  .navbar-holder {
    height: 100%;
  }

  .navbar-container {
    justify-content: flex-end;
    height: 100%;
  }

  .nav-menu-link-holder {
    grid-row-gap: 20px;
    flex-direction: column;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-menu-link-container {
    height: auto;
  }

  .nav-menu {
    border-radius: 5px;
    height: auto;
    padding-top: 3;
  }

  .nav-links {
    grid-row-gap: 20px;
    flex-direction: column;
    height: auto;
  }

  .footer-wrapper {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-direction: column;
  }

  .footer-content {
    grid-column-gap: 60px;
  }

  .featured-logo-wrapper {
    width: 150px;
  }

  .help-holder {
    flex-direction: column;
    display: flex;
  }

  .help-container {
    border-right-style: none;
  }

  .help-link-holder {
    grid-column-gap: 30px;
    flex-direction: row;
  }

  .help-link-container.w--current {
    border-right-style: none;
  }

  .utility-page-wrap {
    padding: 34px;
  }

  .menu-button {
    border: 1px solid #fff0;
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 6px;
    transition: border-color 0.25s, background-color 0.35s;
  }

  .menu-button.w--open {
    border: 1px solid var(--color--acent-color);
    background-color: var(--color--acent-color-dim);
    color: var(--color--acent-color);
    border-radius: 5px;
  }

  .break-down-card-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    width: 60%;
    display: flex;
  }

  .team-gird {
    flex-direction: column;
    width: 60%;
    display: flex;
  }

  .team {
    flex-direction: column;
    align-items: center;
    display: flex;
    overflow: visible;
  }

  .blog-grid-twos-item {
    width: 100%;
  }

  .blog-grid-3x {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .menu-cart-holder {
    margin-left: 24px;
  }

  .footer-heading {
    font-size: 24px;
  }

  .dropdown-toggle {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .hero-section-center-holder.contact-v1 {
    padding-top: 180px;
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
  }

  .colored-block {
    border-radius: 6px;
  }

  .blog-grid-holder {
    flex-direction: column;
    display: flex;
  }

  .contact-form-holder.v3 {
    padding: 0;
  }

  .pricing-grid {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .user-account-wrapper {
    max-width: 100%;
  }

  .oversight-content-holder {
    padding: 25px;
  }

  .mega-menu-holder.w--open {
    position: relative;
    transform: none;
  }

  .brand-holder {
    flex: 1;
    margin-right: 0;
  }

  .dropdown {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .grid-features-holder {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .our-team-facts {
    flex-direction: column;
  }

  .blog-grid-2x {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .contact-images-grid-holder {
    flex-direction: column-reverse;
  }

  .nav-button-holder {
    display: none;
  }

  .bento-grid-01,
  .bento-grid-01.ease-reverse,
  .bento-grid-holder {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .bento-grid-text-holder {
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .bento-grid-text-holder.ease-center-text {
    padding-bottom: 24px;
  }

  .bento-grid-text {
    font-size: 30px;
  }

  .feature-content-grid {
    flex-flow: column;
    display: flex;
  }

  .features-grid-3x {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .feature-item-center {
    padding: 13px;
  }

  .feature-grid {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-flow: column;
    display: flex;
  }

  .iphone-screen-image {
    border-radius: 15px;
  }

  .hero-dashboard-image-2.ease-smaller {
    width: 100%;
  }

  .iphone-image-wrapper-cta {
    right: 12px;
  }

  .ease-iphone-holder {
    width: 140px;
  }

  .ease-cta-dashboard-holder {
    width: 90%;
    padding-left: 6px;
    padding-right: 6px;
  }

  .ease-hero-dashborad-cutout {
    width: 100%;
    min-width: 100%;
  }

  .ease-hero-section-grid {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-flow: column;
  }

  .ease-hero-section-left-side {
    min-width: 0;
  }

  .ease-bento-grid-holder-2,
  .ease-bento-grid-02 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .ease-features-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .bento-grid-5 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .eas-tab-link-01 {
    width: 100%;
  }

  .tabs-01 {
    flex-flow: column;
  }

  .tab-menu-01 {
    width: 100%;
    max-width: none;
    margin-right: 0;
  }

  .hero-section-holder {
    padding-top: 20px; /* Add padding to push content below navbar */
  }

  .ease-hero-section-grid {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-flow: column;
  }

  .animate-on-load-01 {
    margin-top: 20px; /* Add margin to the badge container */
  }

  .badge-holder {
    padding-top: 20px; /* Additional padding for the badge */
  }
}

@media screen and (max-width: 767px) {
  .pricing-wrapper {
    display: none;
  }
  
  .pricing-wrapper2 {
    display: block;
  }
  h1 {
    margin-top: 5px;
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .border-box {
    padding: 2px;
  }

  .section.extra-paddings {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section.contact-v1 {
    min-height: 120vh;
  }

  ._6-col-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .footer-wrapper {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
  }

  .footer-content {
    text-align: left;
    grid-template-columns: 1fr;
    margin-top: 40px;
  }

  .footer-block {
    text-align: left;
    align-items: flex-start;
  }

  .footer-divider {
    margin-top: 60px;
  }

  .help-link-holder {
    flex-direction: column;
  }

  .brand {
    max-width: 90px;
    padding-left: 0;
  }

  .blog-item.with-background.hoizontal {
    border-width: 2px;
    border-color: var(--color--acent-color);
    box-shadow: 0 40px 40px 0 var(--color--acent-color-dim), 0 4px 6px 0 #5f4a2e0a, 0 1px 2px 0 #5f4a2e14;
    flex-direction: column;
    width: 100%;
  }

  .blog-item.with-background.hoizontal-v2 {
    flex-direction: column;
    width: 100%;
  }

  .hero-button-holder {
    margin-top: 24px;
  }

  .company-logo-holder {
    padding-top: 29px;
    padding-bottom: 29px;
  }

  .company-logo-wrapper {
    height: 21px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .trusted-by {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .questions-cta {
    grid-row-gap: 18px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .feature-slider {
    width: 90%;
    margin-left: 5%;
  }

  .contact-form-block {
    width: 100%;
  }

  .pricing-details-holder {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    display: flex;
  }

  .pricing-add-to-cart {
    border-top: 1px solid #2a2837;
    border-left-style: none;
    padding-top: 30px;
    padding-left: 0;
  }

  .cart-holder {
    min-width: 0;
    max-width: none;
  }

  .user-acc-holder {
    width: 100%;
  }

  .user-account-form {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .included-grid {
    grid-template-columns: 1fr;
  }

  .oversight-cta-holder {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
    display: flex;
  }

  .oversight-logo {
    width: 40px;
  }

  .oversight-title {
    font-size: 28px;
  }

  .all-pages-gird {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .pages-holder {
    border-radius: 8px;
  }

  .mega-menu-wrapper {
    width: 100%;
  }

  .mega-menu-wrapper-holder {
    flex-direction: column;
  }

  .divider-vertical {
    width: 100%;
    height: 1px;
  }

  .feature-hero-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .our-team-gird {
    flex-direction: column;
    display: flex;
  }

  .job-position-item {
    text-align: center;
    flex-direction: column;
    grid-template-columns: 1fr 0.25fr;
    align-items: center;
    padding-top: 34px;
    display: flex;
    position: relative;
  }

  .job-position-title {
    color: var(--color--button-color);
    font-size: 24px;
    font-weight: 600;
  }

  .job-arrow-holder {
    position: absolute;
    inset: 44px 41px auto auto;
  }

  .home-hero-text-holder {
    padding-left: 12px;
    padding-right: 12px;
  }

  .home-hero-button-holder {
    margin-top: 12px;
  }

  .hero-paragraph-holder {
    padding-left: 12px;
    padding-right: 12px;
  }

  .bento-grid-text {
    font-size: 20px;
  }

  .bento-grid-image-holder {
    position: relative;
  }

  .bento-grid-image-holder.ease-outside {
    margin-top: -20px;
    margin-bottom: 0;
  }

  .features-grid-3x {
    grid-template-columns: 1fr 1fr;
  }

  .cta-left-right-grid {
    text-align: center;
    display: flex;
  }

  .hero-dashboard-image-2.ease-smaller {
    border-radius: 11px;
  }

  .iphone-image-wrapper-cta {
    bottom: 0;
  }

  .ease-iphone-holder {
    width: 130px;
  }

  .ease-bento-grid-text-wrapper {
    padding: 17px;
  }

  .ease-bento-grid-04 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-flow: column;
    padding: 14px;
    display: flex;
  }

  .ease-bento-grid-content {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding: 16px;
  }

  .ease-bento-grid-content.ease-center-about-image {
    width: 120%;
    position: relative;
  }

  .ease-features-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  .bento-grid-content-wrapper {
    position: relative;
  }

  .bento-grid-heading-02 {
    font-size: 25px;
  }

  .feature-dashboard-image {
    z-index: 1;
    border-radius: 8px;
    width: 100%;
    position: relative;
  }

  .feature-dashboard-holder {
    border-radius: 11px;
    padding: 18px;
  }

  .feature-bg-image {
    position: absolute;
    inset: 0%;
  }

  .animate-on-load-01 {
    margin-top: 30px; /* More margin for mobile */
  }

  .nav-links-custom-holder {
    visibility: hidden;
  }
}

#trusted-by-id {
  display: block;
}

#trusted-by-id2 {
  display: none;
}

@media screen and (max-width: 532px) {
  .gradient-title {
    font-size: 40px;
    text-align: center;
  }

  .bold-text {
    font-size: 60px;
    text-align: center;
  }
  .badge {
    display: none;
  }

  .hero-paragraph-holder {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .animate-on-load-04 {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .ease-hero-dashborad-cutout {
    display: none;
  }

  .ease-bento-grid-heading-01 {
    text-align: center;
  }

  #trusted-by-id {
    display: none;
  }

  #trusted-by-id2 {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 30px;
    font-weight: 400;
  }

  ._4-col-grid {
    grid-template-columns: 1fr 1fr;
  }

  .black-background-padding {
    padding: 7px;
  }

  .container {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .container.nav-container-wrapper {
    width: 100%;
  }

  .style-guide-box-holder {
    padding: 4px;
  }

  .style-guide-box-container {
    grid-row-gap: 7px;
  }

  .colors-buttons {
    flex-direction: column;
    padding-top: 29px;
    padding-bottom: 29px;
  }

  ._8-col-grid {
    grid-template-columns: 1fr 1fr;
  }

  ._6-col-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .navbar {
    top: 0;
  }

  .nav-menu-link-container {
    width: 100%;
  }

  .footer-divider {
    grid-row-gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding-top: 20px;
  }

  .featured-logo-wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .early-access-card {
    min-width: 0;
  }

  .power-feature-card-container {
    border-radius: 20px;
  }

  .pricing-list-holder {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .pricing-footer-holder {
    grid-row-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }

  .cta-form-holder {
    min-width: 0;
  }

  .form {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-block {
    width: 100%;
  }

  .submit-button {
    border-radius: 8px;
    width: 100%;
    height: 51px;
    position: static;
  }

  .help-content-holder {
    padding-top: 30px;
  }

  .help-link-holder {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .figma-file-holder {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .figma-file-container {
    grid-row-gap: 32px;
    flex-direction: column;
  }

  .full-width-image {
    border-radius: 7px;
  }

  .change-log-block-holder {
    flex-flow: column;
    display: flex;
  }

  .utility-page-wrap {
    padding: 17px;
  }

  .break-down-card-grid {
    width: 100%;
  }

  .visable-image-content-holder {
    border-radius: 12px;
  }

  .value-wrapper {
    flex-direction: column;
  }

  .team-gird,
  .animate-on-load-04 {
    width: 100%;
  }

  .blog-item:hover {
    transform: none;
  }

  .blog-grid-twos-item {
    width: 100%;
  }

  .blog-grid-twos-wrapper {
    max-width: 100%;
  }

  .blog-grid-3x {
    grid-row-gap: 30px;
  }

  .blog-grid-3x-image-holder {
    border-radius: 4px;
    height: 250px;
  }

  .blog-details---title {
    font-size: 33px;
  }

  .blog-details-main-image-holder {
    border-radius: 6px;
  }

  .menu-cart-holder {
    margin-left: 4px;
  }

  .cart-hero {
    align-items: flex-start;
  }

  .cart-item {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .price-and-quantity {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }

  .product-name {
    max-width: 160px;
    font-size: 19px;
  }

  .product-thumbnail {
    width: 50px;
  }

  .product-cart-price {
    font-size: 18px;
  }

  .cart-footer {
    padding-top: 12px;
  }

  .checkout-form {
    padding: 12px;
  }

  .block-content {
    padding: 8px;
  }

  .field-label {
    font-size: 13px;
  }

  .hero-section-center-holder {
    align-items: flex-start;
    padding-top: 0;
  }

  .hero-section-center-holder.contact-v2 {
    padding-left: 11px;
    padding-right: 11px;
  }

  .hero-center-text,
  .center-hero-paragraph-holder {
    text-align: left;
  }

  .hero-button-holder {
    flex-direction: column;
    align-items: stretch;
  }

  .hero-app-holder {
    margin-top: 50px;
  }

  .section-paddings {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .new-features-holder {
    padding-top: 30px;
  }

  .feature-grid-content-holder {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .testimonal-slider {
    margin-top: 40px;
  }

  .testimonal-mask {
    width: 100%;
  }

  .testimonal-slide {
    padding: 15px;
  }

  .left-arrow {
    justify-content: flex-start;
  }

  .right-arrow {
    margin-left: 0;
  }

  .questions-cta {
    padding: 18px;
  }

  .hero-blog-holder {
    margin-top: 30px;
  }

  .contact-form-holder {
    padding: 0;
  }

  .form-fields-holder {
    flex-direction: column;
  }

  .form-fields-holder.no-gab {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .pricing-grid {
    width: 100%;
  }

  .pricing {
    padding: 22px;
  }

  .price {
    font-size: 40px;
  }

  .pricing-list-wrapper {
    width: 100%;
  }

  .pricing-list-item {
    width: 100%;
    max-width: 100%;
  }

  .pricing-details-holder {
    width: 100%;
    padding: 18px 17px;
  }

  .cart-holder {
    height: 100vh;
    min-height: 100vh;
    padding: 13px;
  }

  .login-form-wrapper {
    padding: 12px;
  }

  .page-wrapper {
    padding: 22px;
  }

  .checkbox {
    min-width: 16px;
    min-height: 16px;
  }

  .form-header-margins {
    margin-top: 0;
    margin-bottom: 12px;
  }

  .password-buttons {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .subscription {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    border-bottom: 1px solid var(--color--hoved-color);
    flex-direction: column;
    align-items: flex-start;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .subscription:last-child {
    border-bottom-style: none;
  }

  .subscription-tilte-holder {
    margin-left: 0;
    margin-right: 0;
  }

  .right {
    align-items: flex-start;
  }

  .home-sale-section,
  .home-sale-grid._01,
  .home-sale-grid._02 {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .screenshot-holder {
    width: 50%;
  }

  .included-grid {
    margin-top: 30px;
  }

  .all-pages-gird {
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }

  .mega-menu-holder.w--open {
    border-radius: 6px;
    max-height: 360px;
    overflow: auto;
  }

  .mega-menu-content {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .menu-title {
    font-size: 20px;
  }

  .grey-link {
    font-size: 14px;
  }

  .mega-menu-wrapper-holder {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    padding: 17px 29px;
  }

  .dropdown {
    width: 100%;
  }

  .grid-feature-title-holder {
    flex-direction: column;
    align-items: flex-start;
  }

  .grid-feature-title-holder.vertical-way {
    align-items: center;
  }

  .grid-paragraph {
    font-size: 13px;
  }

  .grid-features-holder {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .testimonal-info-wrapper {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  .about-hero-wrapper {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .about-image {
    border-radius: 2px;
  }

  .our-team-gird {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .job-position-item {
    text-align: left;
    align-items: flex-start;
  }

  .job-position-title {
    max-width: 90%;
  }

  .job-arrow-holder {
    top: 39px;
    right: 21px;
  }

  .blog-grid-2x {
    grid-row-gap: 30px;
  }

  .hero-description {
    text-align: left;
  }

  .contact-heading {
    align-items: flex-start;
  }

  .contact-orange-dot-holder {
    display: none;
  }

  .contact-cta-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 17px;
    padding-bottom: 17px;
    display: grid;
  }

  .contact-images-grid {
    width: 100%;
    min-width: 0;
  }

  .contact-image-holder {
    height: auto;
  }

  .contact-image {
    border-radius: 4px;
  }

  .hero-app-light._01 {
    filter: blur(10px);
    width: 200px;
    height: 200px;
  }

  .hero-app-light._02 {
    filter: blur(8px);
    width: 200px;
    height: 200px;
  }

  .hero-app-light._30 {
    filter: blur(11px);
    width: 200px;
    height: 200px;
  }

  .home-hero-text-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .home-hero-button-holder {
    flex-flow: column;
    width: 100%;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .hero-dashboard-image {
    border-radius: 3px;
  }

  .hero-dashboard-image.smaller {
    width: 100%;
  }

  .center-hero {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
  }

  .paragraph {
    font-size: 16px;
  }

  .hero-paragraph-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-link {
    padding-left: 16px;
    padding-right: 16px;
  }

  .bento-grid-01 {
    flex-flow: column;
    display: flex;
  }

  .paragraph-s {
    font-size: 14px;
  }

  .feature-check-holder {
    min-width: 24px;
    min-height: 24px;
  }

  .feature-content-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .features-grid-3x {
    flex-flow: column;
    display: flex;
  }

  .feature-item-center {
    border-radius: 8px;
  }

  .feature-content,
  .feature-grid {
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }

  .feature-grid.reverse {
    flex-flow: column-reverse;
  }

  .short-testimonial-holder {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 21px;
  }

  .feature-grid-2x {
    margin-top: 24px;
    display: flex;
  }

  .number-fact {
    font-size: 40px;
  }

  .second-title {
    font-size: 32px;
  }

  .cta-button-holder {
    flex-flow: column;
    width: 100%;
    display: flex;
  }

  .cta-left-right-grid {
    padding-left: 31px;
    padding-right: 31px;
  }

  .cta-form {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
  }

  .iphone-screen-image {
    border-radius: 5px;
  }

  .hero-dashboard-image-2 {
    border-radius: 3px;
  }

  .hero-dashboard-image-2.ease-smaller {
    border-radius: 5px;
  }

  .iphone-image-wrapper-cta {
    width: 270px;
    inset: auto 0% 0% auto;
  }

  .ease-cta-holder-03 {
    border-radius: 14px;
    height: auto;
    padding-bottom: 0;
    padding-left: 11px;
    padding-right: 11px;
    box-shadow: inset 0 -20px 99px 1px #c7d3ea0d;
  }

  .ease-center-hero-2 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
  }

  .ease-iphone-holder {
    width: 20%;
  }

  .ease-hero-form,
  .ease-cta-dashboard-holder {
    width: 100%;
  }

  .ease-submit-button-form {
    position: relative;
  }

  .ease-hero-dashborad-cutout {
    border-radius: 7px;
  }

  .ease-hero-section-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .ease-hero-section-left-side {
    min-width: 0;
  }

  .ease-bento-grid-text-wrapper {
    padding: 0;
  }

  .ease-bento-grid-heading-01 {
    font-size: 32px;
  }

  .ease-bento-grid-02 {
    flex-flow: column;
    display: flex;
  }

  .ease-features-grid {
    grid-column-gap: 42px;
    grid-row-gap: 42px;
    flex-flow: column;
    display: flex;
  }

  .center-hero-3 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
  }

  .iphone-image-wrapper {
    inset: 0%;
  }

  .bento-grid-5 {
    flex-flow: column;
    display: flex;
  }

  .tab-link-description {
    font-size: 16px;
  }

  .tab-link-heading {
    font-size: 20px;
  }

  .eas-tab-link-01 {
    padding: 20px;
  }

  .tab-link-content {
    grid-column-gap: 7px;
    grid-row-gap: 7px;
  }

  .feature-text-2 {
    font-weight: 400;
  }

  .animate-on-load-05 {
    width: 100%;
  }
}

#w-node-_3b499776-2085-b760-a10d-d2a90d650d6f-c586e03b,
#w-node-fee04703-97c8-081a-16e1-98921ee9ac09-c586e03b,
#w-node-_9a4b48cb-c53e-6e02-0f82-dfabe514dbc5-c586e03b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_82a6fd79-5d1a-4762-f599-ed3c348df5a2-c586e03b {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-_17dea79c-6bb6-a919-3147-dc0579e9e273-c586e0ba,
#w-node-_2ac5de9e-fdc3-04d4-7101-6a62047434e9-c586e0ba,
#w-node-_5f2290ae-ff04-58be-b503-d3ceb9a8fa14-c586e0ba,
#w-node-_7464091d-8ea7-1eee-a2d8-6e013501adce-c586e0ba,
#w-node-ffc3a719-319d-bf65-e235-174dfe02d028-c586e0ba,
#w-node-_353edb41-7ea7-a47a-ad01-86e351f2a0fa-c586e0ba,
#w-node-dc22844c-5561-f8fb-fb87-c1d838ba4b80-c586e0ba,
#w-node-f4b24048-ce8b-ae80-6e77-cc923f502a6d-c586e0ba,
#w-node-f4b24048-ce8b-ae80-6e77-cc923f502a6f-c586e0ba,
#w-node-f4b24048-ce8b-ae80-6e77-cc923f502a71-c586e0ba,
#w-node-f4b24048-ce8b-ae80-6e77-cc923f502a73-c586e0ba,
#w-node-f4b24048-ce8b-ae80-6e77-cc923f502a75-c586e0ba,
#w-node-_8670b2a3-7f24-448c-35b6-7301e5bd48b6-c586e0ba,
#w-node-_8670b2a3-7f24-448c-35b6-7301e5bd48b8-c586e0ba,
#w-node-_8670b2a3-7f24-448c-35b6-7301e5bd48ba-c586e0ba,
#w-node-_8670b2a3-7f24-448c-35b6-7301e5bd48bc-c586e0ba,
#w-node-_8670b2a3-7f24-448c-35b6-7301e5bd48be-c586e0ba,
#w-node-_465f9278-9576-e0b5-0d5d-fc72dc899e3c-c586e0ba,
#w-node-_465f9278-9576-e0b5-0d5d-fc72dc899e3e-c586e0ba,
#w-node-_465f9278-9576-e0b5-0d5d-fc72dc899e40-c586e0ba,
#w-node-_465f9278-9576-e0b5-0d5d-fc72dc899e42-c586e0ba,
#w-node-_465f9278-9576-e0b5-0d5d-fc72dc899e44-c586e0ba,
#w-node-_1a28ebeb-9162-d3cb-8468-7300a05229ba-c586e0d4,
#w-node-_3738d700-6092-977b-dbd8-fc37873a5c10-c586e0d8,
#w-node-a967a5cd-72b8-012e-8485-06f1c37bcb4a-c586e0d8,
#w-node-_52d76a9a-1087-f61e-3e91-874f000138e2-c586e0d8,
#w-node-f752b425-49c6-6552-b98f-c3ea64a84885-c586e0d9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a840e015-0d87-0e29-c501-30c7327fa4e5-c586e0d9 {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-_0d3834e0-81f8-5a9f-5f9e-fe1bb3933fa1-c586e0d9,
#w-node-_2dbfd16f-133e-520a-c5e9-4072ff61ca4c-c586e0dc,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f92b-c586e0de,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f932-c586e0de,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f939-c586e0de,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f940-c586e0de,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f947-c586e0de,
#w-node-c68c8ce7-f3b3-2ff8-fb6f-e33f7993f94e-c586e0de,
#w-node-_41b7e57d-8768-0483-5c9c-7908c96cf293-c586e0de,
#w-node-_41b7e57d-8768-0483-5c9c-7908c96cf29a-c586e0de,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56dc-c586e0e0,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56e3-c586e0e0,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56ea-c586e0e0,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56f1-c586e0e0,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56f8-c586e0e0,
#w-node-_29ab175b-eae8-6d1a-8757-1315374c56ff-c586e0e0,
#w-node-_44914cf0-77e1-0ad6-4229-b1858e11c2a2-c586e0e0,
#w-node-_44914cf0-77e1-0ad6-4229-b1858e11c2a9-c586e0e0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0d289d73-4694-d6fa-c0eb-7073b0f24256-562eabb0 {
  justify-self: end;
}

#w-node-_9c3b245d-ec6e-4c1b-fcac-ff45f7234861-c586e0f5,
#w-node-_7e76c5cb-4155-9b13-6324-193fce04f596-c586e0f6 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_653b365b-9864-8c57-d8f5-b821de792871-c586e0f6,
#w-node-_653b365b-9864-8c57-d8f5-b821de792878-c586e0f6,
#w-node-_653b365b-9864-8c57-d8f5-b821de79287f-c586e0f6,
#w-node-_653b365b-9864-8c57-d8f5-b821de792886-c586e0f6,
#w-node-_653b365b-9864-8c57-d8f5-b821de79288d-c586e0f6,
#w-node-_653b365b-9864-8c57-d8f5-b821de792894-c586e0f6,
#w-node-_8595b4cc-f73c-3ac7-f9ab-37cddb2978a1-c586e0f7,
#w-node-_63c7100f-9342-3d29-c1e8-a026ad70c2f3-c586e0f8,
#w-node-_50d429f7-a276-3f83-9b9a-bc93d36794c4-c586e0ff {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b894a5fe-f86c-a319-2754-9c68f095fdc2-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdc5-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdc8-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdcb-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdce-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdd1-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdd4-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdd7-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fddc-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fddf-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fde2-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fde5-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fde8-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdeb-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdf0-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdf3-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdf6-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fdf9-c586e0ff {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-b894a5fe-f86c-a319-2754-9c68f095fdfe-c586e0ff,
#w-node-b894a5fe-f86c-a319-2754-9c68f095fe02-c586e0ff {
  grid-area: span 1 / span 4 / span 1 / span 4;
  align-self: center;
}

@media screen and (max-width: 767px) {
  #w-node-_82a6fd79-5d1a-4762-f599-ed3c348df5a2-c586e03b {
    grid-column: span 1 / span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_52d76a9a-1087-f61e-3e91-874f000138e2-c586e0d8 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-a840e015-0d87-0e29-c501-30c7327fa4e5-c586e0d9 {
    grid-row: span 1 / span 1;
  }

  #w-node-_0d3834e0-81f8-5a9f-5f9e-fe1bb3933fa1-c586e0d9 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-b894a5fe-f86c-a319-2754-9c68f095fdfe-c586e0ff,
  #w-node-b894a5fe-f86c-a319-2754-9c68f095fe02-c586e0ff {
    grid-column: span 2 / span 2;
  }
}
